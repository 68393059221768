* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}


.sticky-header {
  position: sticky;
  top: 60px;
  z-index: 99;

  border: 2px solid #c1c1c1;
  border-radius: 5px;
  background-color: #fff0f0;
  padding: 10px;
  margin-bottom: 20px;
  overflow: hidden;

  display: flex;
  flex-flow: column;
  justify-items: center;
  align-items: center;
}

.sticky-header__item:not(:last-child) {
  margin-bottom: 10px;
}

@media all and (min-width: 768px) {
  .sticky-header {
    flex-flow: row;
    justify-items: center;
    align-items: center;
    top: 67px;
  }

  .sticky-header__item:not(:last-child) {
    margin-bottom: 0;
    margin-right: 10px;
  }
}
